import React from "react"
import Layout from "../components/layout"
import ContactSection from "../components/contact/contact_section.js"

const ContactPage = () => {
  return (
    <Layout>
      <div className="contact_page">
        <div className="main-wrapper contact_page">
          <div className="main-content">
            <ContactSection />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
