import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const ContactSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulContactUs {
        title
        content {
          raw
        }
      }
    }
  `);
  const { title, content } = data.contentfulContactUs;
  return (
    <div className="contactSecWrapper">
      <h1>{title}</h1>
      {renderRichText(content)}
    </div>
  );
};

export default ContactSection;
